import { createColor } from "django-bananas/colors";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InboxIcon from "@material-ui/icons/Inbox";
import WeekendIcon from "@material-ui/icons/Weekend";
import grey from "@material-ui/core/colors/grey";
import logo from "./static/logo.svg";
import themes, { createBananasTheme } from "django-bananas/themes";

const jacksonsRed = createColor("#ff1800");
const primary = createColor("#44a0d6");

const baseTheme = createBananasTheme(
  themes.default.extend({
    typography: {
      useNextVariants: true,
      fontSize: 14,
    },
    palette: {
      primary: {
        light: primary[300],
        main: primary[500],
        dark: primary[700],
        contrastText: grey[50],
      },
      secondary: {
        main: grey[700],
        light: grey[200],
        contrastText: "#fff",
      },
      tertiary: {
        main: jacksonsRed[500],
        light: jacksonsRed[200],
        contrastText: "#fff",
      },
      error: { main: "#FF1501" },
    },
  }),
);

const theme = baseTheme.extend({
  overrides: {
    MuiButton: {
      root: {
        "& svg": {
          marginRight: baseTheme.spacing.unit,
        },
      },
      contained: {
        boxShadow: "none",
      },
      outlinedPrimary: {
        color: baseTheme.palette.primary.main,
        border: 0,
        boxShadow: `inset 0 0 0 2px ${baseTheme.palette.primary.main}`,
        "&:hover": {
          border: 0,
          boxShadow: `inset 0 0 0 2px ${baseTheme.palette.primary.main}`,
        },
        "&:disabled": {
          border: "0 !important",
          boxShadow: `inset 0 0 0 2px ${baseTheme.palette.action.disabled}`,

          "&:hover": {
            border: "0 !iportant",
            boxShadow: `inset 0 0 0 2px ${baseTheme.palette.action.disabled}`,
          },
        },
      },
    },

    MuiButtonBase: {
      root: {
        "&$disabled": {
          pointerEvents: "auto !important",
          cursor: "not-allowed !important",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        bottom: `-1.35em`,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
      },
    },
    MuiFab: {
      root: {
        boxShadow: "none",
        color: "white",
        backgroundColor: grey[900],
        textTransform: "none",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
        padding: `${baseTheme.spacing.unit}px ${baseTheme.spacing.unit * 2}px`,
        backgroundColor: "#fff",
        borderRadius: 3,
        border: "1px solid #989898",
      },
      inputMultiline: {
        height: "100%",
      },
      underline: {
        "&::before": { display: "none" },
        "&::after": { display: "none" },
      },
    },
    MuiInputBase: {
      root: {
        height: 50,
        fontSize: 14,
        backgroundColor: "#EDEDED",
        borderRadius: "4px",
        border: "none",
      },
      multiline: {
        height: "auto",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        color: "#333",
        transform: "translate(0, -8px) scale(1) !important",
      },
      outlined: {
        transform: "translate(0, -22px) scale(1) !important",
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "white",
      },
      notchedOutline: {
        borderColor: "transparent !important",
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        borderTop: `1px solid ${baseTheme.palette.divider}`,
      },
    },
    MuiSelect: {
      root: {
        padding: baseTheme.spacing.unit * 2,
        borderRadius: "4px",
        borderColor: "transparent",
      },
      outlined: {
        padding: 0,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        fontSize: 30,
      },
    },
    MuiTab: {
      root: {
        minWidth: "auto !important",
        overflow: "visible !important",
      },
      labelContainer: {
        padding: "8px 0 !important",
      },
    },
    MuiTabs: {
      flexContainer: {
        "& > * + *": {
          marginLeft: `${baseTheme.spacing.unit * 4}px !important`,
        },
      },
    },
    MuiTouchRipple: {
      root: {
        display: "none",
      },
    },
    MuiPrivateTabIndicator: {
      root: {
        bottom: baseTheme.spacing.unit,
        backgroundColor: `${baseTheme.palette.primary.main} !important`,
      },
    },
    MuiBadge: {
      badge: {
        backgroundColor: baseTheme.palette.tertiary.main,
        color: "white",
      },
    },
    MuiTypography: {
      overline: {
        fontWeight: "bold",
        color: baseTheme.palette.text.secondary,
      },
    },
    IntegrationReactSelect: {
      paper: {
        background: "red",
      },
    },
    BananasContent: {
      root: {
        boxShadow: "0 -6px 6px rgba(0, 0, 0, 0.02)",
      },
    },
    BananasProgressBar: {
      bar: {
        backgroundColor: baseTheme.palette.tertiary.main,
      },
    },
    BananasTitleBar: {
      root: {
        backgroundColor: grey[600],
      },
    },
    BananasNavBar: {
      header: {
        backgroundColor: grey[700],
      },
    },
  },
});

export default {
  /* eslint-disable-next-line */
  api: process.env.REACT_APP_API_URL, // url to the api root endpoint.
  pages: route => import(`./pages/${route}`), // dynamically import all needed pages
  logLevel: "DEBUG",
  logo,
  layout: "horizontal",
  navigationProps: {
    dense: true,
  },
  collapsed: true,
  icons: {
    "jacksons.product:list": WeekendIcon,
    "jacksons.order:list": InboxIcon,
    "jacksons.sales:list": AttachMoneyIcon,
  },
  theme,
  branding: "Jacksons Admin",
  title: "Jacksons Admin",
  version: "v. 2.0b1",
};
